import React from "react";
import RichText from '../../core/RichText';
import ReactMarkdown from 'react-markdown';
import { Header5 } from 'src/components/core/typography';
import "./AccordionItem.scss";
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer-ts";

interface Props {
  title?: string;
  description?: string;
  extended?: StoryblokRichtext;
  onToggle: () => unknown;
  active: Boolean;
  className: string;
  i: number;
  faq: Boolean;
  markdown?: Boolean;
}

const AccordionItem = ({ title, description, extended, onToggle, active, className, i, faq, markdown }:Props) => {
  return (
    <div className={`accordion_item ${active ? "active" : ""} ${className}`} key={i}>
      <div className="button cursor-pointer" onClick={onToggle}>
        <Header5 className="mb-0 !text-navy">{title || ""}</Header5>
        <div className="preview">
          { faq && <RichText className={`hidden md:block text-lynch pt-2 h-[1.8rem] overflow-hidden ${active ? "md:hidden" : ""}`} document={description || ""}/> }
          { faq && <p className={`hidden md:block text-navy mt-3 cursor-pointer !bg-transparent ${active ? "md:hidden" : ""}`}>Read More</p> }
        </div>
      </div>
      <div className={`answer_wrapper ${active ? "open pt-2" : ""}`}>
        {!markdown && <RichText className="answer text-lynch" document={description || ""}/>}
        {markdown && <ReactMarkdown>{description || ""}</ReactMarkdown>}
        {!markdown && extended && <RichText className="extended text-lynch pt-2" document={extended || ""}/>}
      </div>
    </div>
  );
};

export default AccordionItem;