import React, { useState } from 'react';
import { Section } from 'src/components/core/Section';
import { AboutUsStoryblok } from 'src/storyblok-component-types';
import { Header2 } from 'src/components/core/typography';
import { StoryblokStoryProps, getFilename, findMatchingLocalFileNode, DynamicImage } from 'src/lib/images';
import ReactMarkdown from 'react-markdown';

import { TabNavItem, TabContent } from 'src/components/shared/Tabs';
import { AccordionItem } from 'src/components/shared/Accordion';
import "./tabs.scss";
  
const TabsSection = (props: AboutUsStoryblok & StoryblokStoryProps) => {
    const [activeTab, setActiveTab] = useState("tab0");
    const tabsContent = props.tabs || [];
    const firstTab = tabsContent[0];
    const [clicked, setClicked] = useState(0);

    const handleToggle = (index:number) => {
        if (clicked === index) {
            return setClicked(0);
        }
        setClicked(index);
    };
    return <Section>
        {/* Component Header */}
        <div className="col-span-12 text-center md:text-left">
            <Header2>{props.header || ""}</Header2>
        </div>

        {/* Mobile Only CTA  */}
        <div className={`tabs md:hidden bg-${props.bg_version} bg-contain bg-bottom cta rounded-2xl grid grid-cols-12 grid-flow-row shadow-lg mb-10 overflow-hidden`}>
            <div className="tab-body first col-span-12 md:col-span-6 mischka flex flex-col justify-center p-10 md:py-14 md:pl-14 md:pr-0">
                <Header2 className="!text-white">{firstTab.title || ""}</Header2>
                <ReactMarkdown>{firstTab.body || ""}</ReactMarkdown>
            </div>
        </div>

        {/* Desktop Tabs */}
        <div className="tabs hidden md:grid grid-cols-12 rounded-xl overflow-hidden md:border-2 md:border-lilac">
            {/* Desktop Tabs - Nav */}
            <ul className="tab-nav flex col-span-4 flex-col flex-wrap list-none border-b-0 pl-0">
                {tabsContent.map((tab, i) => {
                    return <TabNavItem key={i} title={tab.title} id={`tab${i}`} activeTab={activeTab} setActiveTab={setActiveTab} className={`px-6 py-6 text-lg font-semibold w-full block cursor-pointer text-navy hover:no-underline hover:bg-lilac hover:text-bluewood ${i == 0 ? "first" : ""}`}/>
                })}   
            </ul>

            {/* Desktop Tabs - Body */}
            <div className="tab-content block col-span-12 md:col-span-8 md:bg-lilac relative">
                {tabsContent.map((tab, i) => {
                    const localImageFileNode = findMatchingLocalFileNode(getFilename(tab.image?.filename || ""), props.story);
                    return <TabContent key={i} id={`tab${i}`} activeTab={activeTab} className={`tab-body p-8 h-auto bg-lilac ${i == 0 ? "first" : ""}`}>
                        <ReactMarkdown className={i == 0 ? "mischka" : ""}>{tab.body || ""}</ReactMarkdown>
                        <div className="hidden lg:block absolute bottom-0 right-8">
                            <DynamicImage fileNode={localImageFileNode} alt="About Userve" className="block max-w-[275px] max-h-[275px]" />
                        </div>
                    </TabContent>
                })}  
            </div>
        </div>

        {/* Mobile Body - Accordion */} 
        <div className="md:hidden accordion col-span-12" id="accordion-cta-mobile">
            {tabsContent.map((tab, index) => {
                return <AccordionItem key={index} i={index} title={tab.title} description={tab.body} onToggle={() => handleToggle(index)} active={clicked === index} faq={false} markdown={true}
                    className={`faq border-solid border-1 border-melrose md:border-transparent p-6 pl-14 rounded-xl mb-6 overflow-hidden last:mb-0 w-full ${props.layout == "title-right" ? "bg-white" : "bg-white md:bg-lilac "} ${index == 0 && "hidden"}`} />
            })}
        </div>   
    </Section>;
};

export default TabsSection;
